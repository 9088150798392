import { render, staticRenderFns } from "./CollapsedItemCard.vue?vue&type=template&id=8a7c332c&scoped=true"
import script from "./CollapsedItemCard.vue?vue&type=script&lang=ts"
export * from "./CollapsedItemCard.vue?vue&type=script&lang=ts"
import style0 from "./CollapsedItemCard.vue?vue&type=style&index=0&id=8a7c332c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.8.1_webpack@5.94.0_@swc+core@1.3.82___lodash@4.17.21_prettier_xvzp2jsppiiwuefmmhkmhvivqu/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8a7c332c",
  null
  
)

export default component.exports