import { render, staticRenderFns } from "./StandaloneSearchBox.vue?vue&type=template&id=05db06e2&scoped=true"
import script from "./StandaloneSearchBox.vue?vue&type=script&lang=ts"
export * from "./StandaloneSearchBox.vue?vue&type=script&lang=ts"
import style0 from "./StandaloneSearchBox.vue?vue&type=style&index=0&id=05db06e2&prod&scoped=true&lang=scss"
import style1 from "./StandaloneSearchBox.vue?vue&type=style&index=1&id=05db06e2&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.8.1_webpack@5.94.0_@swc+core@1.3.82___lodash@4.17.21_prettier_xvzp2jsppiiwuefmmhkmhvivqu/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05db06e2",
  null
  
)

export default component.exports