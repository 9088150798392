import { render, staticRenderFns } from "./ItemPoints.vue?vue&type=template&id=5b8b83f6&scoped=true"
import script from "./ItemPoints.vue?vue&type=script&lang=ts"
export * from "./ItemPoints.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.8.1_webpack@5.94.0_@swc+core@1.3.82___lodash@4.17.21_prettier_xvzp2jsppiiwuefmmhkmhvivqu/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b8b83f6",
  null
  
)

export default component.exports